<template>
    <BasePopup v-model="shown" @close="close">
        <div class="order-create">
            <div class="order-create__content">
                <template v-if="!orderSuccess">
                    <div class="order-create__title">Оформление заказа</div>
                    <template v-if="cart.products && cart.products.length">
                        <div class="order-create__products">
                            <template v-for="item in cart.products">
                                <div 
                                    :key="`cart-products-${item.unique_code}`"
                                    class="order-create__product"
                                >
                                    <div class="order-create__product__picture" :style="`background-image: url(${imageSrc(item.product.picture)})`"></div>
                                    <div class="order-create__product__content">
                                        <div class="order-create__product__title">{{ item.product.code }} {{ item.product.title }} - {{ item.size.title }}</div>
                                        <div class="order-create__product__options" v-if="item.options && item.options.length">{{ options(item) }}</div>
                                        <div class="order-create__product__content__wrap">
                                            <div class="order-create__product__price">{{ priceFormated(item.full_price) }}</div>
                                            <div class="order-create__product__quantity">{{ item.quantity }} шт</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="order-create__user">
                            <ValidationObserver ref="user_validator" slim>
                                <ValidationProvider v-slot="{ errors }" rules="required">
                                    <BaseTextField
                                        :error-messages="errors[0]"
                                        v-model="user.name"
                                        label="Имя"
                                        autocomplete="off"
                                        outlined
                                    />
                                </ValidationProvider>
                                <ValidationProvider v-slot="{ errors }" rules="required">
                                    <BaseTextField
                                        :error-messages="errors[0]"
                                        v-model="user.phone"
                                        label="Телефон"
                                        type="tel"
                                        autocomplete="off"
                                        outlined
                                    />
                                </ValidationProvider>
                                <ValidationProvider v-slot="{ errors }" rules="email">
                                    <BaseTextField
                                        :error-messages="errors[0]"
                                        v-model="user.email"
                                        label="Email"
                                        autocomplete="off"
                                        outlined
                                    />
                                </ValidationProvider>
                            </ValidationObserver>
                        </div>

                        <div class="order-create__buttons">
                            <BaseButton class="button-fill" @click.stop="createOrder">
                                <span>Оформить заказ</span><span>{{ priceFormated(cart.full_price) }}</span>
                            </BaseButton>
                        </div>
                    </template>
                </template>
                <template v-if="orderSuccess">
                    <div class="order-create__created">
                        Заказ успешно создан. <br>В ближайшее время с вами свяжется наш менеджер для уточнения деталей.
                    </div>
                </template>
            </div>
        </div>
    </BasePopup>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BasePopup from '../common/BasePopup';
    import BaseButton from '../common/BaseButton';
    import BaseTextField from '../common/BaseTextField';

    import { imageSrc, priceFormated } from '../../helpers';

    export default {
        name: 'PopupOrderCreate',
        components: {
            ValidationProvider, 
            ValidationObserver,
            BasePopup,
            BaseButton,
            BaseTextField,
        },
        data: () => ({
            imageSrc, 
            priceFormated,
            shown: false,
            orderSuccess: false,
            user: {
                name: '',
                phone: '',
                email: ''
            },
        }),
        computed: {
            ...mapState('orders', {
                cart: state => state.cart
            }),
        },
        mounted() {
            let this_ = this;
            this_.$root.$on('popupOrderCreateShow', async () => {
                this_.shown = true;
            });
            this_.$root.$on('popupOrderCreateHide', () => {
                this_.shown = false;
            });
        },
        methods: {
            async createOrder() {
                const valid = await this.$refs['user_validator'].validate();
                if(valid) {
                    store.commit('orders/SET_CART_USER', this.user);
                    await store.dispatch('orders/create');
                    this.orderSuccess = true;
                }
            },
            close() {
                this.shown = false;
                this.user = {};
            },
        }
    }
</script>

<style lang="scss">
    .order-create {
        position: relative;

        &__content {
            width: 100%;
            height: 100%;
            min-width: 300px;
            padding: 20px;
            background: #FFFFFF;
        }

        &__title {
            padding: 14px 20px 14px 0;
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
        }

        &__products { 
            height: calc(100% - 220px);
            margin: 0 -10px;
            padding: 0 10px;
            overflow-x: hidden;
            overflow-y: auto; 
            border-top: 1px solid #F5F4F2;
        }
        &__product {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid #F5F4F2;

            &__picture {
                width: 60px;
                height: 90px;
                overflow: hidden;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            &__content {
                width: calc(100% - 70px);
                padding: 4px 0;

                &__wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 4px;
                }
            }
            
            &__title {
                display: -webkit-box;
                overflow: hidden;
                font-size: 16px;
                margin-bottom: 4px;
                line-height: 19px;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
            
            &__options {
                font-size: 12px;
                margin-bottom: 4px;
                line-height: 12px;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                color: #9E9B98;
            }
            
            &__price,
            &__quantity {
                display: inline-block;
                position: relative;
                font-weight: 400;
                white-space: nowrap;
                font-size: 18px;
                line-height: 20px;
            }
        }

        &__user {
            padding-top: 20px;
        }

        &__buttons {

            .v-btn__content {
                display: flex;
                justify-content: space-between;
            }

            &__fixed {
                width: calc(100% - 70px);
                position: absolute;
                bottom: 20px;

                .v-btn__content {
                    display: block;
                }
            }
        }

        &__created {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 300px;
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            text-align: center;
        }
    }
</style>
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header__content"},[_c('div',{staticClass:"header__logo",on:{"click":_vm.home}}),(!_vm.$route.meta.hideButtons)?_c('div',{staticClass:"header__buttons"},[_c(VIcon,{attrs:{"size":"20","color":_vm.cart.full_price > 0 ? 'primary' : ''},on:{"click":function($event){return _vm.$root.$emit('popupCartShow')}}},[_vm._v("mdi-cart")]),(_vm.$route.name === 'home')?_c(VMenu,{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"order-cart__icon-button"},[_c(VIcon,{attrs:{"size":"24"}},[_vm._v("mdi-menu")])],1)])]}}],null,false,1417999753)},[_c(VList,[[_c(VListItem,{on:{"click":function($event){return _vm.goToAncor('about')}}},[_c(VListItemTitle,[_vm._v("О компании")])],1),_c(VListItem,{on:{"click":function($event){return _vm.goToAncor('catalog')}}},[_c(VListItemTitle,[_vm._v("Каталог")])],1)]],2)],1):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
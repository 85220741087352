import Vue from 'vue';
import Vuex from 'vuex';

import files from './modules/files';
import orders from './modules/orders';
import categories from './modules/categories';
import products from './modules/products';
import sizes from './modules/sizes';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        files,
        orders,
        categories,
        products,
        sizes,
    }
})

<template>
    <div :class="`base-counter ${ small ? 'base-counter__small' : '' }`">
        <BaseButton 
            class="base-counter__button" 
            :small="small"
            @click.stop="valueMinus">
            <v-icon>mdi-minus</v-icon>
        </BaseButton>
        <div class="base-counter__value"> {{ value }} </div>
        <BaseButton 
            class="base-counter__button" 
            :small="small" 
            @click.stop="valuePlus">
            <v-icon>mdi-plus</v-icon>
        </BaseButton>
    </div>
    
</template>

<script>
    import BaseButton from '../common/BaseButton';

    export default {
        name: 'BaseCounter',
        components: {
            BaseButton,
        },
        props: {
            minValue: {
                type: Number,
                default: null
            },
            maxValue: {
                type: Number,
                default: null
            },
            value: {
                type: Number,
                default: 1
            },
            small: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            valueMinus() {
                if (this.minValue === null || this.value > this.minValue) {
                    this.$emit('change', this.value - 1);
                }
            },
            valuePlus() {
                if (this.maxValue === null || this.value < this.maxValue) {
                    this.$emit('change', this.value + 1);
                }
            }
        }
    }
</script>

<style lang="scss"> 
    .base-counter {
        width: 100%;
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            font-size: 20px;
            background: #F5F4F2;
            border-radius: 12px;
            cursor: pointer;

            .v-icon.v-icon {
                color: #000000;
            }
        }

        &__value {
            min-width: 30px;
            font-size: 16px;
            color: #000000;
            text-align: center;
        }

        &__small {
            height: 30px;

            .base-counter__button {
                width: 30px;

                .v-icon.v-icon {
                    font-size: 18px;
                }
            }
        }
    }
</style>
<template>
    <v-btn
        :class="`base-button ${ small ? 'base-button__small' : '' }`"
        depressed
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot></slot>
    </v-btn>
</template>

<script>
    export default {
        name: 'BaseButton',
        props: {
            small: {
                type: Boolean,
                default: false
            },
        },
        inheritAttrs: false
    }
</script>

<style lang="scss">
.base-button {
    width: 100%;
    height: 48px;
    padding: 0;
    border-radius: 5px !important;
    border: 1px solid #F5F4F2 !important;
    align-self: center;
    background: #F5F4F2 !important;

    &.theme--light.v-btn.v-btn--has-bg {
        background: #e3e3e3 !important;
    }
    &:disabled {
        opacity: 0.5;
        border: 1px solid #F5F4F2 !important;
        .v-btn__content {
            color: #F5F4F2 !important;
        }
    }
    &.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
        background: inherit;
    }
    &.theme--light.v-btn:not(.v-btn--round).v-size--default {
        min-width: unset;
        height: 48px;
        padding: 0;
    }

    .v-btn__content {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: -0.02em;
        text-align: center;
        text-transform: none;
        color: #000000;
        background: none;
        padding: 0 20px;

        .v-icon.v-icon {
            font-size: 25px;
            color: #000000;
        }
    }

    &.button-fill,
    &.button-fill.theme--light.v-btn.v-btn--has-bg {
        background: #F36223 !important;
        .v-btn__content {
            color: #fff !important;
            
            .v-icon, .v-icon.v-icon {
                color: #ffffff;
            }
        }
        &:disabled {
            background: #F36223 !important;
            .v-btn__content {
                color: #fff !important;
            }
        }
    }
    &.button-white {
        background: #fff;
    }

    &__small {
        height: 30px;
        border-radius: 0 !important;

        &.theme--light.v-btn:not(.v-btn--round).v-size--default {
            height: 30px;
        }
    }
}
</style>

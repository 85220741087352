<template>
    <v-text-field
        :value="value"
        class="base-textfield"
        outlined
        v-bind="$attrs"
        v-on="$listeners"
    >
    </v-text-field>
</template>

<script>
    export default {
        name: 'BaseTextField',
        props: {
            value: {
                type: [String, Number],
                default: ''
            }
        },
        inheritAttrs: false,
    }
</script>

<style lang="scss">
    .base-textfield {
		width: 100%;
        padding: 0;
        border-radius: 0 !important;
		align-self: center;

        .v-input__slot {
            height: 48px;
        }
    }
</style>

<template>
    <div class="order-cart">
        <div class="order-cart__content">
            <div class="order-cart__title">Корзина</div>
            <template v-if="cart.products && cart.products.length">
                <div class="order-cart__products">
                    <template v-for="(item, index) in cart.products">
                        <div 
                            :key="`cart-products-${item.unique_code}`"
                            class="order-cart__product"
                        >
                            <div class="order-cart__product__picture" :style="`background-image: url(${imageSrc(item.product.picture)})`"></div>
                            <div class="order-cart__product__content">
                                <div class="order-cart__product__title">{{ item.product.code }} {{ item.product.title }} - {{ item.size.title }}</div>
                                <div class="order-cart__product__options" v-if="item.options && item.options.length">{{ options(item) }}</div>
                                <div class="order-cart__product__content__wrap">
                                    <div class="order-cart__product__price">{{ priceFormated(item.full_price) }}</div>
                                    <div class="order-cart__product__quantity">
                                        <BaseCounter
                                            :value="item.quantity"
                                            :min-value="0"
                                            @change="setCartProductQuantity($event, index)"
                                            small
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="order-cart__buttons">
                    <BaseButton class="button-fill" @click.stop="createOrderPopup">
                        <span>Оформить заказ</span><span>{{ priceFormated(cart.full_price) }}</span>
                    </BaseButton>
                </div>
            </template>
            <template v-else>
                <div class="order-cart__empty">
                    В вашей корзине <br>пока пусто
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated } from '../../helpers';

    import BaseButton from '../common/BaseButton';
    import BaseCounter from '../common/BaseCounter';

    export default {
        name: 'Cart',
        components: {
            BaseButton,
            BaseCounter,
        },
        props: {
        },
        computed: {
            ...mapState('orders', {
                cart: state => state.cart
            }),
        },
        data: () => ({
            imageSrc, 
            priceFormated
        }),
        methods: {
            options(product) {
                if(product.options && product.options.length) {
                    return product.options.map(item => item.option.title).join(' • ');
                }
                return '';
            },
            setCartProductQuantity(quantity, index) {
                if(quantity === 0) {
                    store.dispatch('orders/removeCartProduct', index);
                } else {
                    store.dispatch('orders/setCartProductQuantity', { quantity, index: index });
                }
            },
            async createOrderPopup() {
                this.$root.$emit('popupCartHide');
                this.$root.$emit('popupOrderCreateShow');
            }
        }
    }
</script>

<style lang="scss">
    .order-cart {
        position: relative;

        &__content {
            width: 100%;
            height: 100%;
            min-width: 300px;
            padding: 20px;
            background: #FFFFFF;
        }

        &__title {
            padding: 14px 20px 14px 0;
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
        }

        &__products { 
            height: calc(100% - 220px);
            margin: 0 -10px;
            padding: 0 10px;
            overflow-x: hidden;
            overflow-y: auto; 
            border-top: 1px solid #F5F4F2;
        }
        &__product {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid #F5F4F2;

            &__picture {
                width: 60px;
                height: 90px;
                overflow: hidden;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            &__content {
                width: calc(100% - 70px);
                padding: 4px 0;

                &__wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 4px;
                }
            }
            
            &__title {
                display: -webkit-box;
                overflow: hidden;
                font-size: 16px;
                margin-bottom: 4px;
                line-height: 19px;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
            
            &__options {
                font-size: 12px;
                margin-bottom: 4px;
                line-height: 12px;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                color: #9E9B98;
            }
            
            &__price {
                display: inline-block;
                position: relative;
                font-weight: 400;
                white-space: nowrap;
                font-size: 18px;
                line-height: 20px;
            }
        }

        &__buttons {
            padding-top: 20px;

            .v-btn__content {
                display: flex;
                justify-content: space-between;
            }

            &__fixed {
                width: calc(100% - 70px);
                position: absolute;
                bottom: 20px;

                .v-btn__content {
                    display: block;
                }
            }
        }

        &__empty {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 300px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
        }
    }
</style>
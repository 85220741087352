import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';

import axios from './plugins/axios';
import './plugins/vue-moment';
import './plugins/vee-validate';
import './plugins/vue-meta';
import './styles/main.scss';

Vue.config.productionTip = false;

import VueZoomer from 'vue-zoomer';
Vue.use(VueZoomer)

new Vue({
    router,
    store,
    axios,
    vuetify,
    render: function (h) { 
        return h(App) 
    }
}).$mount('#app')

<template>
    <div class="header">
        <div class="header__content">
            <div class="header__logo" @click="home"></div>
            <div class="header__buttons" v-if="!$route.meta.hideButtons">
                <!-- <BaseButton class="button-fill header__buttons__cart" @click="$root.$emit('popupCartShow')">
                    <v-icon>mdi-cart</v-icon>&nbsp;{{ priceFormated(cart.full_price) }}
                </BaseButton> -->
                <v-icon @click="$root.$emit('popupCartShow')" size="20" :color="cart.full_price > 0 ? 'primary' : ''">mdi-cart</v-icon>
                
                <v-menu left bottom offset-y v-if="$route.name === 'home'">
                    <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <div class="order-cart__icon-button">
                                <v-icon size="24">mdi-menu</v-icon>
                            </div>
                        </div>
                    </template>
                    <v-list>
                        <template>
                            <v-list-item @click="goToAncor('about')">
                                <v-list-item-title>О компании</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="goToAncor('catalog')">
                                <v-list-item-title>Каталог</v-list-item-title>
                            </v-list-item>
                            <!-- <template v-for="item in categories">
                                <v-list-item :key="`header-menu-${ item.id }`" @click="goToAncor(item.code)">
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </template> -->
                        </template>
                    </v-list>
                </v-menu>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { priceFormated } from '../helpers';
    import { shortAddressFormat } from '../helpers/user';

    import BaseButton from './common/BaseButton';

    export default {
        name: 'Header',
        components: {
            BaseButton
        },
        data: () => ({
            priceFormated,
            shortAddressFormat
        }),
        computed: {
            ...mapState('users', {
                user: state => state.user
            }),
            ...mapState('categories', {
                categories: state => state.entities
            }),
            ...mapState('orders', {
                cart: state => state.cart
            }),
        },
        async mounted() {
        },
        methods: {
            home() {
                this.$router.push('/');
            },
            goToAncor(ancor) {
                this.$router.push(`/#${ancor}`);
            },
            logout() {
                store.dispatch('users/logout');
            }
        }
    }
</script>

<style lang="scss">
    .header {
        width: 100%;
        height: 70px;
        position: sticky;
        top: 0;
        background-color: #010101;
        z-index: 10;

        &__content {
            width: 100%;
            max-width: 1280px;
            padding: 10px 20px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
        }

        &__logo {
            width: 85px;
            height: 50px;
            padding-left: 55px;
            color: #ffffff;
            font-size: 30px;
            line-height: 50px;
            background: url('../assets/logo.svg') left center no-repeat;
            background-size: contain;
            cursor: pointer;

            @media screen and (max-width: 460px) {
                height: 50px;
                padding-left: 45px;
                font-size: 24px;
                line-height: 40px;
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 140px;

            & > div,
            & > button {
                margin-left: 20px;
            }

            .v-icon, .v-icon.v-icon {
                font-size: 40px;
                color: #ffffff;
            }
        }
    }
</style>
<template>
    <v-app>
        <v-main>
            <Header />
            <div class="content">
                <router-view />
            </div>
            <Footer />
        </v-main>
        <PopupCart />
        <PopupOrderCreate />
    </v-app>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import Header from './components/Header';
    import Footer from './components/Footer';
    import PopupCart from './components/order/PopupCart';
    import PopupOrderCreate from './components/order/PopupOrderCreate';

    export default {
        name: 'TIDwear',
        metaInfo: {
            title: 'TIDwear',
            titleTemplate: '%s | TIDwear'
        },
        components: {
            Header,
            Footer,
            PopupCart,
            PopupOrderCreate,
        },
        computed: {
        },
        async mounted() {
            await store.dispatch('orders/fetchCart');
        },
    }
</script>

<style lang="scss">
    .content {
        width: 100%;
        min-height: calc(100vh - 180px);
        padding: 20px;

        @media screen and (max-width: 460px) {
            padding: 10px 10px 0;
        }
    }
</style>
export const shortAddressFormat = (address) => {
    const addressArray = [];
    if(address.street) {
        addressArray.push(address.street.trim());
    }
    if(address.building) {
        addressArray.push('д. ' + address.building.trim());
    }
    return addressArray.join(', ');
};

export const fullAddressFormat = (address) => {
    const addressArray = [];
    if(address.city) {
        addressArray.push('город ' + address.city.trim());
    }
    if(address.street) {
        addressArray.push('улица ' + address.street.trim());
    }
    if(address.building) {
        addressArray.push('дом ' + address.building.trim());
    }
    if(address.entrance) {
        addressArray.push('подъезд ' + address.entrance.trim());
    }
    if(address.floor) {
        addressArray.push('этаж ' + address.floor.trim());
    }
    if(address.appartment) {
        addressArray.push('кв/офис ' + address.appartment.trim());
    }
    return addressArray.join(', ');
};
import axios from '../../plugins/axios';
import { copyObject } from '../../helpers';

const EMPTY_CART = {
    full_price: 0,
    products: [],
    user: {},
    additional_data: {}
};

export default {
    namespaced: true,

    state: {
        statuses: {},
        entities: [],
        entitiesCount: 0,
        entity: {},
        cart: copyObject(EMPTY_CART)
    },

    mutations: {
        SET_STATUSES(state, payload) {
            state.statuses = payload;
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },

        /*** Cart ***/
        SET_CART(state, payload) {
            state.cart = copyObject(payload);
        },
        CLEAR_CART(state) {
            state.cart = copyObject(EMPTY_CART);
        },
        SET_CART_USER(state, payload) {
            state.cart.user = payload;
        },
        ADD_CART_PRODUCT(state, payload) {
            let added = false;
            for(const product of state.cart.products) {
                if(product.unique_code === payload.product.unique_code) {
                    product.quantity += payload.product.quantity;
                    added = true;
                }
            }
            if(!added) {
                state.cart.products.push(cartProductFormatData(payload));
            }
            state.cart.full_price = cartFullPrice(state);
        },
        SET_CART_PRODUCT_QUANTITY(state, payload) {
            state.cart.products[payload.index].quantity = payload.quantity;
            state.cart.full_price = cartFullPrice(state);
        },
        REMOVE_CART_PRODUCT(state, index) {
            state.cart.products.splice(index, 1);
            state.cart.full_price = cartFullPrice(state);
        }
        /*** Cart ***/
    },

    actions: {
        async statuses({ commit }) {
            const { data } = await axios.get('/common/orders/statuses');
            commit('SET_STATUSES', data);
        },
        async fetch({ commit }) {
            const { data } = await axios.get('/front/orders');
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/orders/${ id }`);
            commit('SET_ENTITY', data);
        },
        async create({ state, commit, dispatch }) {
            const { data } = await axios.post(`/front/orders`, state.cart);
            commit('SET_ENTITY', data);
            dispatch('clearCart');
        },

        /*** Cart ***/
        async fetchCart({ commit }) {
            const cart = localStorage.getItem('tidwear-cart');
            if(cart) {
                commit('SET_CART', JSON.parse(cart));
            }
        },
        async clearCart({ commit, dispatch }) {
            commit('CLEAR_CART');
            localStorage.removeItem('tidwear-cart');
        },
        async setCartEntity({ commit, dispatch }, data) {
            commit('SET_CART_ENTITY', data);
            dispatch('cartToStorage');
        },
        async addCartProduct({ commit, dispatch }, data) {
            commit('ADD_CART_PRODUCT', data);
            dispatch('cartToStorage');
        },
        async setCartProductQuantity({ commit, dispatch }, data) {
            commit('SET_CART_PRODUCT_QUANTITY', data);
            dispatch('cartToStorage');
        },
        async removeCartProduct({ commit, dispatch }, data) {
            commit('REMOVE_CART_PRODUCT', data);
            dispatch('cartToStorage');
        },
        async cartToStorage({ state }) {
            localStorage.setItem('tidwear-cart', JSON.stringify(state.cart));
        },
        /*** Cart ***/
    },

    getters: {}
}

const cartFullPrice = ({ cart }) => {
    return cart.products.reduce((result, product) => {
        result += +product.full_price * product.quantity;
        return result;
    }, 0);
}

const cartProductFormatData = ({ product = {}, size = {} }) => {
    return {
        unique_code: product.unique_code,
        product: {
            id: product.id,
            code: product.code,
            title: product.title,
            picture: product.picture,
            price: product.price,
        },
        size: {
            id: size.id,
            title: size.title,
            code: size.code,
        },
        full_price: product.price,
        quantity: product.quantity,
    }
};
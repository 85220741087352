import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue')
    },
    {
        path: '/category/:code',
        name: 'category',
        component: () => import('../views/Category.vue')
    },
    {
        path: '/product/:code',
        name: 'product',
        component: () => import('../views/Product.vue')
    },
]

const router = new VueRouter({
    routes,
    base: '/',   
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else 
        if (savedPosition) {
            return savedPosition;
        }  else {
            return { x: 0, y: 0 }
        }
    }
});

export default router

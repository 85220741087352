<template>
    <BasePopup v-model="shown" @close="close">
        <Cart class="popup-order-cart" />
    </BasePopup>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import BasePopup from '../common/BasePopup';
    import Cart from './Cart';

    export default {
        name: 'PopupLogin',
        components: {
            BasePopup,
            Cart,
        },
        data: () => ({
            shown: false,
            profile: {},
        }),
        computed: {
            ...mapState('users', {
                user: state => state.user
            }),
        },
        mounted() {
            let this_ = this;
            this_.$root.$on('popupCartShow', async () => {
                this_.shown = true;
            });
            this_.$root.$on('popupCartHide', () => {
                this_.shown = false;
            });
        },
        methods: {
            async save() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    store.commit('users/SET_USER', { ...this.user, ...this.profile });
                    await store.dispatch('users/save');
                    this.close();
                }
            },
            close() {
                this.shown = false;
                this.profile = {};
            },
        }
    }
</script>

<style lang="scss">
    .popup-order-cart {
        height: 100%;

        .order-cart__content {
            padding: 0;
        }
        .order-cart__products {
            max-height: calc(90vh - 260px);

            @media screen and (max-width: 460px) {
                max-height: calc(100vh - 260px);
            }
        }

        .order-cart__buttons__fixed {
            @media screen and (max-width: 460px) {
                width: 100%;
            }
        }
    }
</style>
<template>
    <div class="footer">
        <div class="footer__content">
            <div class="footer__content__row">
                <div>
                    <div class="footer__logo" @click="home"></div>
                    <div class="footer__copyright">©&nbsp;{{ year() }}&nbsp;TIDwear</div>
                </div>
                <div class="text-right">
                    <div class="footer__contacts">Отдел продаж</div>
                    <div class="footer__email"><a href="mailto:sales@tidwear.ru">sales@tidwear.ru</a></div>
                    <div class="footer__phone"><a href="tel:+79965676747">+7 (996) 567-67-47</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        methods: {
            home() {
                this.$router.push('/');
            },
            year() {
                return new Date().getFullYear();
            }
        }
    }
</script>

<style lang="scss">
    .footer {
        width: 100%;
        background-color: #010101;

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 110px;
            padding: 10px 20px;
            max-width: 1280px;
            margin: 0 auto;
            color: #ffffff;

            &__row {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &__copyright {
            padding: 10px 0 0 0;
        }

        &__logo {
            height: 43px;
            background: url('../assets/logo.svg') left center no-repeat;
            background-size: contain;
            cursor: pointer
        }

        &__contacts {
            
        }
        &__email {
            font-size: 20px;
            
            & > a {
                text-decoration: none !important;
            }
        }
        &__phone {
            font-size: 20px;
            
            & > a {
                color: #ffffff !important;
                text-decoration: none !important;
            }
        }
    }
</style>